<template src="./sticky.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Sticky",
  data: function () {
    return {
      user: {},
      posts: [],
    }
  },
  created: function(){
    let self = this;
    self.user = self.$store.getters.getUser;
    let params = '?page=1&per_page=100&sticky=true';
    return Http.request('GET', '/user/dashboard/posts' + params).then(function (response) {
      self.parsePosts(response.data);
      self.$emit('created', response.data.length);
    });
  },
  methods: {
    deletePost (post, key) {
      let self = this;
      self.$bvModal.msgBoxConfirm('Möchtest du diesen Post wirklich löschen?', {
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          Http.request('DELETE', '/user/dashboard/posts/' + post.id).then(function (response) {
            self.posts.splice(key, 1);
          });
        }
      });
    },
    parsePosts(items){
      let link;
      let self = this;
      const regex = /href="\?id(.*?)"/g;

      items.forEach(item => {
        while((link = regex.exec(item.text)) !== null) {
          item.text = item.text.replace('?id' + link[1], "/frame/" + window.btoa('/login/templates/content.php?id' + link[1]));
        }
        self.posts.push(item);
      });
    }
  }
}
</script>

<style scoped>
</style>
