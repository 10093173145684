<template src="./friends.html"></template>

<script>
import Http from '@/shared/http-config'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: "Friends",
  components: {VuePerfectScrollbar},
  data: function () {
    return {
      recommendations: [],
      birthdays: [],
      requests: [],
    }
  },
  created: function(){
    let self = this;
    let recommendations = Http.request('GET', '/user/friends/recommendations');
    let birthdays = Http.request('GET', '/user/friends/birthdays');
    let requests = Http.request('GET', '/user/friends/requests');

    Promise.all([recommendations, birthdays, requests])
      .then(function (results) {
        self.recommendations = results[0].data;
        self.birthdays = results[1].data;
        self.requests = results[2].data;

        self.$emit('created', (self.recommendations.length + self.birthdays.length + self.requests.length));
      });
  },
  methods: {
    addFriend(id, key){
      let self = this;
      Http.request('POST', '/user/friends/requests/' + id).then(function (response) {
        self.recommendations.splice(key, 1);
        self.$bvToast.toast('Freundschaftsanfrage gesendet', {
          title: 'Gesendet',
          variant: 'success',
          autoHideDelay: 5000
        });
      });
    },
    acceptRequest(id, key){
      let self = this;
      Http.request('POST', '/user/friends/requests/' + id + '/accept').then(function (response) {
        self.requests.splice(key, 1);
        self.$bvToast.toast('Ihr seit nun Freunde', {
          title: 'Bestätigt',
          variant: 'success',
          autoHideDelay: 5000
        });
      });
    },
    deleteRequest(id, key){
      let self = this;
      Http.request('DELETE', '/user/friends/requests/' + id).then(function (response) {
        self.requests.splice(key, 1);
        self.$bvToast.toast('Anfrage gelöscht', {
          title: 'Gelöscht',
          variant: 'warning',
          autoHideDelay: 5000
        });
      });
    }
  }
}
</script>

<style scoped>
</style>
