<template src="./dashboard.html"></template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Http from '@/shared/http-config'
import draggable from 'vuedraggable'
import next_events from './widgets/next_events/Events'
import my_events from './widgets/my_events/Events'
import friends from './widgets/friends/Friends'
import posts from './widgets/posts/Posts'
import training from './widgets/training/Training'
import website from './widgets/website/Website'
import sticky from './widgets/sticky/Sticky'
import introJs from '@/shared/intro.js';
import {isMobile} from '@/shared/utils.js'

export default {
  name: "Dashboard",
  components: {VuePerfectScrollbar, draggable, next_events, my_events, friends, posts, training, website, sticky},
  data: function () {
    return {
      user: {},
      widgets: [],
      allWidgets: [],
      showWidgets: {},
    }
  },
  computed: {
    isMobile () {
      return isMobile();
    },
  },
  created: function(){
    let self = this;
    self.user = self.$store.getters.getUser;
    self.widgets = self.user.widgets;
    Http.request('GET', '/user/dashboard').then(function (response) {
      response.data.forEach(function (widget) {
        self.$set(self.showWidgets, widget.alias, true);
        self.allWidgets.push({
          text: widget.name,
          value: widget,
        });
      });

      if(self.widgets.length === 0){
        self.widgets = response.data;
      }

      introJs.firstLoad('dashboard');
    });

    introJs.setOption('steps', [
      {
        element: '#btnIntroJs',
        intro: "Mit dem ? - Symbol kannst du jederzeit die Tour erneut ansehen.",
      },
      {
        element: '#dashboard h1',
        intro: "Im Dashboard findest du einen Überblick zu allen wichtigen Themen. Du kannst es zudem individuell gestalten.",
      },
      {
        element: '#widget-training',
        intro: "Dies ist ein Widget. Widgets geben dir einen Überblick zur entsprechenden Thematik. In diesem Widget findest du zum Beispiel die nächsten Schritte, die du zur Einarbeitung ins System durchlaufen kannst.",
        disableInteraction: true
      },
      {
        element: '#widget-posts',
        intro: "In diesem Widget kannst du durch die News scrollen. Übrigens: Du kannst Widgets per Drag & Drop einfach verschieben und so die Reihenfolge ändern.",
      },
      {
        element: '#widget-next_events',
        intro: "Hier hast du einen Überblick über die nächsten Events. Eine komplette Übersicht erhältst du durch Runterscrollen im Widget und Klick auf \"Alle Events ansehen\".",
        disableInteraction: true
      },
      {
        element: '#widget-my_events',
        intro: "Hier hast du einen Überblick über deine nächsten, gebuchten sowie vergangenen Events.",
        disableInteraction: true
      },
      {
        element: '#widget-friends',
        intro: "Möchtest du neue Freunde zu deiner Liste ergänzen, dann kannst du unter \"Vorschläge\" einfach auf \"+ Hinzufügen\" klicken.",
      },
      {
        element: '#widget-website',
        intro: "Hier findest du einen Überblick über deine Website-Statistiken. Mit Klick auf den Button findest du Details hierzu.",
      },
      {
        element: '#btn-settings',
        intro: "Deine Auswahl an Widgets kannst du hier jederzeit ändern und neue Widgets hinzuwählen oder bestehende abwählen. Viel Erfolg bei der Gestaltung deines persönlichen Dashboards. Der Wizard ist nun abgeschlossen.",
      },
    ]);
  },
  methods: {
    saveWidgets () {
      let self = this;
      Http.request('POST', '/user/dashboard', this.widgets).then(function () {
        self.$store.dispatch('fetchUser', true);
        self.$bvModal.hide('settings');
      });
    },
    widgetCreated (widgetDataLength, widget) {
      this.$set(this.showWidgets, widget.alias, (widgetDataLength > 0));
    },
    psYReachEnd (widget) {
      if(this.$refs[widget.alias][0] && typeof(this.$refs[widget.alias][0].psYReachEnd) === 'function'){
        this.$refs[widget.alias][0].psYReachEnd();
      }
    },
  }
}
</script>

<style scoped>
  .card {
    border-radius: 0;
    border: 1px solid #dddddd
  }
  .card .card-body {
    padding: 0.75rem;
  }
</style>
