<template src="./website.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Website",
  data: function () {
    return {
      visitors: 0,
      requests: 0,
    }
  },
  created: function(){
    let self = this;
    Http.request('GET', '/website/stats/widget').then(function (response) {
      self.visitors = response.data.visitors;
      self.requests = response.data.requests;
      self.$emit('created', (self.requests + self.visitors));
    });
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
