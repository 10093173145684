<template src="./events.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Events",
  data: function () {
    return {
      events: [],
    }
  },
  created: function(){
    let self = this;
    Http.request('GET', '/events?page=1&per_page=3').then(function (response) {
      self.events = response.data;
      self.$emit('created', response.data.length);
    });
  },
}
</script>

<style scoped>
  .card {
    border-radius: 0;
  }
</style>
