<template src="./events.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Events",
  data: function () {
    return {
      events: [],
      eventType: 'booked'
    }
  },
  created: function(){
    let self = this;
    this.getEvents().then(function () {
      if(self.events.length === 0){
        self.eventType = 'visited';
        self.getEvents().then(function () {
          self.$emit('created', self.events.length);
        });
      }
      else {
        self.$emit('created', self.events.length);
      }
    });
  },
  methods: {
    changeEventType(type){
      this.eventType = type;
      this.events = [];
      this.getEvents();
    },
    getEvents(){
      let self = this;
      return Http.request('GET', '/events?page=1&per_page=3&' + self.eventType + '=1').then(function (response) {
        self.events = response.data;
      });
    }
  }
}
</script>

<style scoped>
  .card {
    border-radius: 0;
  }
</style>
