<template src="./posts.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Posts",
  data: function () {
    return {
      posts: [],
      user: {},
      meta: {},
      metaDefault: {
        current_page: 0,
        total: 0,
        per_page: 10
      },
      postType: 'official'
    }
  },
  created: function(){
    let self = this;
    self.user = self.$store.getters.getUser;
    self.meta = self.metaDefault;
    self.$emit('created', 1);
    this.getPosts();
  },
  methods: {
    deletePost (post, key) {
      let self = this;
      self.$bvModal.msgBoxConfirm('Möchtest du diesen Post wirklich löschen?', {
        headerBgVariant: 'primary',
        title: 'Post löschen?',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          Http.request('DELETE', '/user/dashboard/posts/' + post.id).then(function (response) {
            self.posts.splice(key, 1);
          });
        }
      });
    },
    changePostType(type){
      this.postType = type;
      this.meta = this.metaDefault;
      this.posts = [];
      this.getPosts();
    },
    getPosts(){
      let self = this;
      let params = '?page=' + (self.meta.current_page + 1) + '&per_page=' + self.meta.per_page + '&' + self.postType + '=true';
      return Http.request('GET', '/user/dashboard/posts' + params).then(function (response) {
        self.parsePosts(response.data);
        self.meta = response.meta;
      });
    },
    psYReachEnd () {
      if(this.posts.length > 0){
        this.getPosts();
      }
    },
    parsePosts(items){
      let link;
      let self = this;
      const regex = /href="\?id(.*?)"/g;

      items.forEach(item => {
        while((link = regex.exec(item.text)) !== null) {
          item.text = item.text.replace('?id' + link[1], "/frame/" + window.btoa('/login/templates/content.php?id' + link[1]));
        }
        self.posts.push(item);
      });
    }
  }
}
</script>

<style scoped>
</style>
